// Added on 4/5/2021 to ease updating chart data from .js file
// Just pass in chart object and formatted data series
function setChartSeries(objChart, dataset) {
	var i = 0;

	while (objChart.chart.series.length) {
		objChart.chart.series[0].remove(); // Clear out any existing data
	}
	for (i = 0; i < dataset.length; i++) {
		objChart.chart.addSeries(dataset[i], false);
	}
}

function setChartDrilldown(objChart, dataset) {
	var i;
	var n;
	var series = objChart.chart.series[0];

	for (n = 0; n < series.data.length; n++) {
		for (i = 0; i < dataset.length; i++) {
			if (series.data[n].name === dataset[i].name) {
				objChart.chart.addSeriesAsDrilldown(series.data[n], dataset[i]);
			}
		}
	}
}

function refreshChart(objChart) {
	objChart.chart.redraw();
}

export { setChartSeries };
export { setChartDrilldown };
export { refreshChart };
