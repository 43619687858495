const viewableCalendar = function() {
    let calendarEl = document.getElementById('calendar');

    let vCalendar = new Calendar( calendarEl, {
        plugins: [ interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin ],
        weekNumbers: true,
        weekends: false,
        nowIndicator: true,
        initialView: 'dayGridMonth',
    //     themeSystem: 'jquery-ui',
    //     navLinks: true,
        eventSources: [
            {
                url: '/timesheets.json',
                color: '#FAF29B',
                textColor: 'white'
            }
        ]
    });
    return {
        render: function () {
            vCalendar.render();
        },
    };
};

export { viewableCalendar };
