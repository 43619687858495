// Refer to https://dev.to/morinoko/adding-custom-javascript-in-rails-6-1ke6
const SessionManager = function () {
// Private Variables
    let sessionTimeoutSeconds = 30 * 60;                                // Session timeout Default is 30 minutes
    let promptSeconds = 5 * 60;                                         // Default Alert is when 5 minutes are left
    let secondsBeforePrompt = sessionTimeoutSeconds - promptSeconds;
    let originalTitle = document.title;                                 // grab the HTML <title> (for later)
    let expireSessionUrl = '/timeout';                                  // URL to call when expiring session
    let $dlg;
    let displayCountdownIntervalId;
    let promptToExtendSessionTimeoutId;
    let count = promptSeconds;                                          // Set our counter

// Just a private implementation to actually start our countdown before popup
    let startSessionManager = function () {
        promptToExtendSessionTimeoutId = window.setTimeout(promptToExtendSession, secondsBeforePrompt * 1000);
    };
    let refreshSession = function () {
        if ($dlg !== undefined) {
            $dlg.dialog('close');                                       // Close the jQuery Dialog
        }
        stopSessionManager();
        startSessionManager();                                          // ... start it all over!
    };

// Private Functions
    let endSession = function () {
        if ($dlg !== undefined) {
            $dlg.dialog('close');                                       // Close the jQuery Dialog
        }
        stopSessionManager();
        location.href = expireSessionUrl;                               // Redirect to the expiration URL
    };

    let displayCountdown = function () {
        let countdown = function () {
            let cd = new Date(count * 1000),                   // Returns milliseconds since 01/01/70
                minutes = cd.getUTCMinutes(),                           // Grab the minutes
                seconds = cd.getUTCSeconds();                           // Grab the seconds

            document.title = 'Expire in ' + ("0" + minutes).slice(-2) + ':' + ("0" + seconds).slice(-2);     // Update the HTML title
            $('#sm-countdown').html(("0" + minutes).slice(-2) + ':' + ("0" + seconds).slice(-2));            // Update the countdown display
            if (count === 0) {                                          // If we reached zero,
                document.title = 'Session Expired';                     // update the HTML title
                endSession();                                           // and end the session
            }
            count--;
        };
        countdown();                                                    // Call the function once
        displayCountdownIntervalId = window.setInterval(countdown, 1000); // Call the function every second
    };

    let promptToExtendSession = function () {
        $dlg = $('#sm-countdown-dialog').dialog({                       // See the HTML below
            title: 'Session Timeout Warning',
            width: 350,
            height: 300,
            buttons: [
                {
                    text: 'Continue',
                    open: function () {
                        $(this).addClass('continuecls');
                    },
                    click: function () {
                        $(this).dialog('close');                        // Close the dialog
                        refreshSession();                               // Refresh the session
                        document.title = originalTitle;                 // Change the title back
                    }
                },
                {
                    text: 'Log Out',
                    open: function () {
                        $(this).addClass('logoutcls');
                    },
                    click: function () {
                        endSession();                                   // End the session
                    }
                }
            ],
            show: {
                effect: "bounce",
                duration: 1500
            },
            hide: {
                effect: "fade",
                duration: 1000
            }
        });
        count = promptSeconds;                                          // Set our counter
        displayCountdown();                                             // Show that dialog!
    };

    let stopSessionManager = function () {
        window.clearInterval(displayCountdownIntervalId);
        window.clearTimeout(promptToExtendSessionTimeoutId);
    };

// Public Functions
    return {
        start: function () {
            startSessionManager();
        },

        stop: function () {
            stopSessionManager();
        },

        extend: function () {
            refreshSession();
        }
    };
}();

export { SessionManager }

document.addEventListener('DOMContentLoaded', function() {
    $(document).on('click', function () {
        SessionManager.extend();
    });

    $(document).on('keypress', function () {
        SessionManager.extend();
    });
});
