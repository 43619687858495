const editableCalendar = function() {
    let calendarEl = document.getElementById('calendar');

    let eCalendar = new Calendar( calendarEl, {
        header: {
            left: 'prevYear,prev,next,nextYear today',
            center: 'title',
            right: 'month,agendaWeek,agendaDay, listDay,listWeek,listMonth'
        },
        themeSystem: 'jquery-ui',
        selectable: true,
        lazyFetching: true,
        selectHelper: true,
        editable: true,
        weekNumbers: true,
        weekends: false,
        minTime: "07:00:00",
        maxTime: "20:00:00",
        eventLimit: true,
        nextDayThreshold: '00:00',
        schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
        navLinks: true,
        nowIndicator: true,
        views: {
            prevYear: {
                //buttonText: new moment().year() - 1
            },
            nextYear: {
                //buttonText: new moment().year() + 1
            },
            basicWeek: {
                buttonText: 'Basic Week'
            },
            month: {
                buttonText: 'Month View'
            },
            agendaWeek: {
                buttonText: 'Weekly Agenda'
            },
            agendaDay: {
                buttonText: 'Daily Agenda'
            },
            listDay: {
                buttonText: 'Day List'
            },
            listWeek: {
                buttonText: 'Week List'
            },
            listMonth: {
                buttonText: 'Month List'
            },
            listYear: {
                buttonText: 'Year List'
            }
        },
        eventSources: [
            {
                url: '/events.json',
                color: '#FAF29B',
                textColor: 'white'
            }
        ]
        // select: function (start, end) {
        //     const event_data = {
        //         start_date: start.format(),
        //         end_date: end.format()
        //     };
        //     $.ajax({
        //         url: '/events/new.js',
        //         data: event_data,
        //         type: 'GET'
        //     });
        //     editableCalendar('unselect');
        // },

        // eventMouseover: function (event) {
        //     $(this).popover({
        //         title: event.title,
        //         content: event.description,
        //         placement: 'top',
        //         trigger: 'manual',
        //         container: 'body'
        //     }).popover('show');
        // },

        // eventMouseout: function () {
        //     $(this).popover('hide');
        // },

        // eventDrop: function (event) {
        //     var event_data;
        //     event_data = {
        //         event: {
        //             id: event.id,
        //             start_date: event.start.format(),
        //             end_date: event.end.format(),
        //             title: event.title,
        //             all_day: event.allDay
        //         }
        //     };
        //     $.ajax({
        //         url: event.update_url,
        //         data: event_data,
        //         type: 'PATCH',
        //         success: function () {
        //             editableCalendar('refetchEvents');
        //         }
        //     });
        // },

        // eventClick: function (event) {
        //     $.getScript(event.edit_url);
        // },

        // eventRender: function (event, element) {
        //     if (event.notify === true) {
        //         element.find('[class*=-title]').prepend('<span class="glyphicon glyphicon-bell"></span> ');
        //     }
        //     if (event.closed === true) {
        //         element.find('[class*=-title]').prepend('<span class="glyphicon glyphicon-remove-circle"></span> ');
        //     }
        // },
        // viewRender: function (view) {
        //     //const y = moment($('.editcalendar').fullCalendar('getDate')).year();
        //     $(".fc-prevYear-button").text(y - 1);
        //     $(".fc-nextYear-button").text(y + 1);
        // }
    });

    return {
        render: function () {
            eCalendar.render();
        },
    };
};

export { editableCalendar };

